@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
@layer utilities{
    .scroll::-webkit-scrollbar {
        width: .5rem;
    }
    
    /* Track */
    .scroll::-webkit-scrollbar-track {
        background: #1c2434;
    }
    
    /* Handle */
    .scroll::-webkit-scrollbar-thumb {
        background: #465168;
        border-radius: 1rem;
    }
}